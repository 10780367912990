import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ImagePager from "@/app/components/ui/imagePager/ImagePager";
import GlowingText from "@/app/components/ui/texts/glowingText/GlowingText";
import * as typography from "@styles/typography.module.css";
import classNames from "classnames";
import * as styles from "./homeTopSection.module.css";
const HomeTopSection = () => {
    return (_jsxs("section", { className: classNames(styles.homeTopSection), children: [_jsx(GlowingText, { children: _jsxs("h1", { className: classNames(typography.header1, typography.centeredText), children: ["Voc\u00EA sonha,", _jsx("br", {}), "a gente", _jsxs("span", { className: classNames(typography.header1Meddon, typography.linearGradientText), children: [" ", "faz"] })] }) }), _jsx(ImagePager, {})] }));
};
export default HomeTopSection;
