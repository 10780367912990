import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IconEmail16px from "@svgs/16px/iconEmail16px.svg";
import IconLocation16px from "@svgs/16px/iconLocation16px.svg";
import { FaWhatsapp } from "react-icons/fa";
import ContactField from "./ContactField";
import * as styles from "./footerContactSection.module.css";
const FooterContactSection = () => {
    return (_jsxs("div", { className: styles.contactSection, children: [_jsx(ContactField, { Icon: FaWhatsapp, title: "(16) 3635-0101:", description: "Segunda a Sexta das 9:00 \u00E0s 18:00" }), _jsx(ContactField, { Icon: IconEmail16px, title: "Email:", description: "viamidiarp@gmail.com" }), _jsx(ContactField, { Icon: IconLocation16px, title: "Endere\u00E7o:", description: "Av. Dr. Francisco Junqueira, 990 - Centro, Ribeir\u00E3o Preto" })] }));
};
export default FooterContactSection;
