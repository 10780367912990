import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SocialMediaButton from "@/app/components/ui/buttons/socialMediaButton/SocialMediaButton";
import Logo from "@/app/components/ui/logo/Logo";
import * as typography from "@styles/typography.module.css";
import constants from "@utils/constants";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import * as styles from "./socialMediaSection.module.css";
const SocialMediaSection = () => {
    return (_jsxs("div", { className: styles.socialMediaSection, children: [_jsx(Logo, {}), _jsx("p", { className: typography.bodySmall, children: "Visite Nossas Redes" }), _jsxs("div", { className: styles.socialMediaButtons, children: [_jsx(SocialMediaButton, { href: constants.whatsappUrl, children: _jsx(FaWhatsapp, {}) }), _jsx(SocialMediaButton, { href: constants.instagramUrl, children: _jsx(FaInstagram, {}) }), _jsx(SocialMediaButton, { href: constants.facebookUrl, children: _jsx(FaFacebook, {}) })] }), _jsxs("p", { className: typography.caption, children: ["\u00A9 2024 - Via Midia Comunica\u00E7\u00E3o Visual.", _jsx("br", {}), "Todos os direitos reservados"] })] }));
};
export default SocialMediaSection;
