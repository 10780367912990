import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ServiceCard from "@/app/components/ui/cards/serviceCard/ServiceCard";
import acmFacadeImage from "@images/acm-facade.jpg";
import carWrapping from "@images/envelopamento-automotivo.jpg";
import dimensionalLetterImage from "@images/letra-caixa.jpg";
import * as colors from "@styles/colors.module.css";
import * as typography from "@styles/typography.module.css";
import BaseHomeSection from "../base/BaseHomeSection";
import * as styles from "./servicesSection.module.css";
const ProductsSection = () => (_jsx(BaseHomeSection, { id: "servicesSection", title: "Conhe\u00E7a nossos servi\u00E7os", children: _jsxs("div", { className: styles.servicesSectionContent, children: [_jsx(ServiceCard, { title: "Fachadas em ACM", Text: ACMFacadesDescription, imageSrc: acmFacadeImage, contentDescription: "Fachada em ACM 3D" }), _jsx(ServiceCard, { title: "Letras Caixa", Text: DimensionalLettersDescription, imageSrc: dimensionalLetterImage, contentDescription: "Letra Caixa Iluminada" }), _jsx(ServiceCard, { title: "Adesivagem de Frotas", Text: CarWrappingDescription, imageSrc: carWrapping, contentDescription: "Adesivagem de Frota" })] }) }));
export default ProductsSection;
const ACMFacadesDescription = () => {
    return (_jsxs("p", { className: typography.bodyLarge, children: ["A ", _jsx("span", { className: colors.acmYellow, children: "fachada" }), " do seu estabelecimento \u00E9 o primeiro contato visual que os clientes t\u00EAm com sua marca, sendo crucial para causar uma", " ", _jsx("span", { className: colors.acmYellow, children: "boa impress\u00E3o" }), " e", " ", _jsx("span", { className: colors.acmYellow, children: "atrair novos consumidores" }), ". Uma fachada atraente e bem cuidada n\u00E3o s\u00F3 destaca seu neg\u00F3cio da concorr\u00EAncia, mas tamb\u00E9m comunica a identidade e os valores da sua marca de forma eficaz."] }));
};
const DimensionalLettersDescription = () => {
    return (_jsxs("p", { className: typography.bodyLarge, children: ["As letras caixa destacam a identidade visual do seu neg\u00F3cio, oferecendo alta visibilidade e uma", " ", _jsx("span", { className: colors.salmon, children: "apar\u00EAncia moderna" }), ". S\u00E3o dur\u00E1veis, personaliz\u00E1veis e podem incluir", " ", _jsx("span", { className: colors.salmon, children: "ilumina\u00E7\u00E3o" }), ", valorizando a fachada e transmitindo profissionalismo e credibilidade. Ideais para qualquer tipo de estabelecimento."] }));
};
const CarWrappingDescription = () => {
    return (_jsxs("p", { className: typography.bodyLarge, children: ["A adesivagem automotiva \u00E9 ideal para", " ", _jsx("span", { className: colors.carWrappingBlue, children: "refor\u00E7ar a identidade" }), " da sua empresa e garantir alta visibilidade. Com adesivos dur\u00E1veis e personaliz\u00E1veis, voc\u00EA transforma sua frota em uma poderosa ferramenta de marketing. Esses adesivos oferecem uma", " ", _jsx("span", { className: colors.carWrappingBlue, children: "apar\u00EAncia profissional e marcante" }), ", ajudando a destacar sua marca e ampliar sua presen\u00E7a no mercado."] }));
};
