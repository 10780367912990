import { createContext, useContext } from 'react';
// Create a Context with default values
export const NavbarContext = createContext(undefined);
// Custom hook to use the NavbarHeightContext
export function useNavbarContext() {
    const context = useContext(NavbarContext);
    if (context === undefined) {
        throw new Error('useNabarContext must be used within a NavbarContextProvider');
    }
    return context;
}
