// extracted by mini-css-extract-plugin
var _1 = "typography-module__bodyLarge___krkvL";
var _2 = "typography-module__bodySmall___oONHX";
var _3 = "typography-module__bold___a4lVz";
var _4 = "typography-module__button___Z__ig";
var _5 = "typography-module__caption___i9Zs_";
var _6 = "typography-module__centeredText___IGio7";
var _7 = "typography-module__header1___UunVX";
var _8 = "typography-module__header1Meddon___mGpGN";
var _9 = "typography-module__header2___h7Z1J";
var _a = "typography-module__linearGradientText___VLHaX";
var _b = "typography-module__radialGradientText___fkt_b";
var _c = "typography-module__regular___xGExz";
var _d = "typography-module__subHeader1___icpI3";
var _e = "typography-module__subHeader2___Sx191";
export { _1 as "bodyLarge", _2 as "bodySmall", _3 as "bold", _4 as "button", _5 as "caption", _6 as "centeredText", _7 as "header1", _8 as "header1Meddon", _9 as "header2", _a as "linearGradientText", _b as "radialGradientText", _c as "regular", _d as "subHeader1", _e as "subHeader2" }
