import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import colors from "@colors/colors";
import * as typography from "@styles/typography.module.css";
import * as styles from "./roundedButton.module.css";
const RoundedButton = ({ text, href, textColor, iconColor, icon: Icon, backgroundColor = colors.black, background, }) => {
    const containerStyle = background != undefined
        ? { background: background }
        : { backgroundColor: backgroundColor };
    const iconStyle = {
        color: iconColor,
    };
    const textStyle = {
        color: textColor,
    };
    return (_jsxs("a", { className: styles.roundedButton, href: href, style: containerStyle, target: "_blank", children: [_jsx(Icon, { style: iconStyle }), _jsx("p", { className: typography.button, style: Object.assign({}, textStyle), children: text })] }));
};
export default RoundedButton;
