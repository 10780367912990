import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as typography from "@styles/typography.module.css";
import Studio1001 from "@svgs/studio1001.svg";
import * as styles from "./footer.module.css";
import FooterContactSection from "./sections/contact/FooterContactSection";
import PagesSection from "./sections/pages/PagesSection";
import SafeWebsiteSection from "./sections/safeBrowsing/SafeWebsiteSection";
import SocialMediaSection from "./sections/socialMedia/SocialMediaSection";
import { useMediaQuery } from "@mui/material";
const Footer = () => {
    return (_jsxs("footer", { className: styles.container, children: [_jsx(TopSection, {}), _jsx(BottomSection, {})] }));
};
export default Footer;
const TopSection = () => {
    return (_jsxs("div", { className: styles.topSection, children: [_jsx(SocialMediaSection, {}), _jsx(PagesSection, {}), _jsx(FooterContactSection, {}), _jsx(SafeWebsiteSection, {})] }));
};
const BottomSection = () => {
    const isLargeScreen = useMediaQuery("(min-width:768px)");
    return (_jsxs("div", { className: styles.bottomSection, children: [_jsxs("div", { className: styles.createdBy, children: [_jsx("p", { className: typography.caption, children: "Criado por" }), _jsx(Studio1001, {})] }), isLargeScreen ? (_jsx("p", { className: typography.caption, children: "Via Midia Comunica\u00E7\u00E3o Visual \u2022 CNPJ 33.565.366/0001-67" })) : (_jsxs("p", { className: typography.caption, children: ["Via Midia Comunica\u00E7\u00E3o Visual", _jsx("br", {}), "CNPJ 33.565.366/0001-67"] }))] }));
};
