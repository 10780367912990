import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InstagramButton from "@/app/components/ui/buttons/InstagramButton";
import WazeButton from "@/app/components/ui/buttons/WazeButton";
import WhatsAppButton from "@/app/components/ui/buttons/WhatsappButton";
import PhoneNumber from "@/app/components/ui/texts/phoneNumber/PhoneNumber";
import * as colors from "@styles/colors.module.css";
import * as typography from "@styles/typography.module.css";
import classNames from "classnames";
import * as styles from "./contactsCard.module.css";
const ContactsCard = () => {
    return (_jsxs("div", { className: styles.contactsCardContainer, children: [_jsxs("p", { className: typography.bodyLarge, children: ["Solicite um", " ", _jsx("strong", { className: classNames(colors.electricPink), children: "or\u00E7amento" }), " ", "para que possamos transformar sua marca tamb\u00E9m"] }), _jsxs("div", { className: classNames(styles.contactsCardButtons), children: [_jsx(PhoneNumber, { text: "(16) 99406-5410", phoneNumberComplete: "+5516994065410" }), _jsx(WhatsAppButton, {}), _jsx(InstagramButton, {}), _jsx(WazeButton, {})] })] }));
};
export default ContactsCard;
