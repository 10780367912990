import { jsx as _jsx } from "react/jsx-runtime";
import bombomImage from "@images/homeImagePager/Bombom.jpg";
import dcorImage from "@images/homeImagePager/Dcor.jpg";
import gibaImage from "@images/homeImagePager/Giba.jpg";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { animated, useTransition } from "react-spring";
import * as styles from "./imagePager.module.css";
const images = [dcorImage, bombomImage, gibaImage];
const contentDescriptions = [
    "Fachada em Letra Caixa - DCor - Ribeirão Preto",
    "Fachada em Letra Caixa - Bombom - Ribeirão Preto",
    "Fachada em ACM - IBuy Giba - Ribeirão Preto",
];
const ImagePager = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const transitions = useTransition(currentImageIndex, {
        key: currentImageIndex, // Use the index as the key
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 1000 },
    });
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => prevIndex === images.length - 1 ? 0 : prevIndex + 1);
        }, 6000); // Change image every 8 seconds
        return () => clearInterval(interval);
    }, []);
    return (_jsx("div", { className: classNames(styles.imagePager), children: transitions((style, index) => (_jsx(animated.img, { src: images[index], alt: contentDescriptions[index], style: Object.assign({}, style), className: classNames(styles.imagePagerImage) }, index))) }));
};
export default ImagePager;
