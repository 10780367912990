import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Footer from "@components/layout/footer/Footer";
import Navbar from "@components/layout/navbar/Navbar";
import { NavbarContext, useNavbarContext, } from "@components/layout/navbar/NavbarContext";
import Home from "@pages/home/Home";
import { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
const App = () => {
    return (_jsx(Router, { children: _jsx(Layout, { children: _jsx(Routes, { children: _jsx(Route, { path: "/", element: _jsx(Home, {}) }) }) }) }));
};
export default App;
const Layout = ({ children }) => {
    const [navbarHeight, setNavbarHeight] = useState(140);
    return (_jsx(NavbarContext.Provider, { value: { navbarHeight, setNavbarHeight }, children: _jsxs("div", { className: "screenContent", children: [_jsx(Navbar, {}), _jsx(ChildComponent, { children: children }), _jsx(Footer, {})] }) }));
};
const ChildComponent = ({ children }) => {
    const { navbarHeight } = useNavbarContext();
    return _jsx("div", { style: { paddingTop: navbarHeight }, children: children });
};
