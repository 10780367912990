import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Logo from "@/app/components/ui/logo/Logo";
import constants from "@utils/constants";
import { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import { useNavbarContext } from "./NavbarContext";
import * as styles from "./navbar.module.css";
import { useMediaQuery } from "@mui/material";
const Navbar = () => {
    const navbarRef = useRef(null);
    const { setNavbarHeight } = useNavbarContext();
    const [isVisible, setIsVisible] = useState(true);
    const isLargeScreen = useMediaQuery("(min-width:768px)");
    let lastScrollY = 0;
    const handleScroll = () => {
        if (typeof window !== "undefined") {
            const currentScrollY = window.scrollY;
            // Check if scrolling down
            if (currentScrollY > lastScrollY) {
                setIsVisible(false); // Hide navbar
            }
            else {
                setIsVisible(true); // Show navbar
            }
            lastScrollY = currentScrollY; // Update last scroll position
        }
    };
    const animationProps = useSpring({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(-50px)",
        config: { duration: 300 },
    });
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => {
        if (navbarRef.current) {
            const navbarElement = navbarRef.current;
            const computedStyle = window.getComputedStyle(navbarElement);
            const marginTop = parseFloat(computedStyle.marginTop);
            const height = navbarElement.offsetHeight;
            setNavbarHeight(height + marginTop);
        }
    }, [navbarRef]);
    return (_jsxs(animated.nav, { style: Object.assign({}, animationProps), className: styles.navbar, ref: navbarRef, children: [_jsx(Logo, {}), isLargeScreen && (_jsxs("ul", { className: styles.navbarLinks, children: [_jsx("li", { children: _jsx("a", { href: "#servicesSection", children: "Servi\u00E7os" }) }), _jsx("li", { children: _jsx("a", { href: "#clientsSection", children: "Clientes" }) }), _jsx("li", { children: _jsx("a", { href: "#contactSection", children: "Contato" }) }), _jsx("li", { children: _jsx("a", { href: constants.whatsappUrl, target: "_blank", className: styles.navbarLinkButton, children: "Or\u00E7amento" }) })] }))] }));
};
export default Navbar;
