import { jsx as _jsx } from "react/jsx-runtime";
import LogoA248px from "@svgs/logos/48px/logoA248px.svg";
import LogoBelgo48px from "@svgs/logos/48px/logoBelgo48px.svg";
import LogoCacauShow48px from "@svgs/logos/48px/logoCacauShow48px.svg";
import LogoCopacabana48px from "@svgs/logos/48px/logoCopacabana48px.svg";
import LogoGiba48px from "@svgs/logos/48px/logoGiba48px.svg";
import LogoInService48px from "@svgs/logos/48px/logoInService48px.svg";
import LogoNatsumi48px from "@svgs/logos/48px/logoNatsumi48px.svg";
import LogoNestle48px from "@svgs/logos/48px/logoNestle48px.svg";
import LogoNovaExpress48px from "@svgs/logos/48px/logoNovaExpress48px.svg";
import LogoOdontoCompany48px from "@svgs/logos/48px/logoOdontoCompany48px.svg";
import LogoResolv48px from "@svgs/logos/48px/logoResolv48px.svg";
import LogoA232px from "@svgs/logos/32px/logoA232px.svg";
import LogoBelgo32px from "@svgs/logos/32px/logoBelgo32px.svg";
import LogoCacauShow32px from "@svgs/logos/32px/logoCacauShow32px.svg";
import LogoCopacabana32px from "@svgs/logos/32px/logoCopacabana32px.svg";
import LogoGiba32px from "@svgs/logos/32px/logoGiba32px.svg";
import LogoInService32px from "@svgs/logos/32px/logoInService32px.svg";
import LogoNatsumi32px from "@svgs/logos/32px/logoNatsumi32px.svg";
import LogoNestle32px from "@svgs/logos/32px/logoNestle32px.svg";
import LogoNovaExpress32px from "@svgs/logos/32px/logoNovaExpress32px.svg";
import LogoOdontoCompany32px from "@svgs/logos/32px/logoOdontoCompany32px.svg";
import LogoResolv32px from "@svgs/logos/32px/logoResolv32px.svg";
import BaseHomeSection from "../base/BaseHomeSection";
import * as styles from "./clientsSection.module.css";
import { useMediaQuery } from "@mui/material";
const largeLogos = [
    _jsx(LogoNestle48px, {}),
    _jsx(LogoCacauShow48px, {}),
    _jsx(LogoOdontoCompany48px, {}),
    _jsx(LogoNovaExpress48px, {}),
    _jsx(LogoBelgo48px, {}),
    _jsx(LogoA248px, {}),
    _jsx(LogoNatsumi48px, {}),
    _jsx(LogoInService48px, {}),
    _jsx(LogoGiba48px, {}),
    _jsx(LogoCopacabana48px, {}),
    _jsx(LogoResolv48px, {}),
];
const smallLogos = [
    _jsx(LogoNestle32px, {}),
    _jsx(LogoCacauShow32px, {}),
    _jsx(LogoOdontoCompany32px, {}),
    _jsx(LogoNovaExpress32px, {}),
    _jsx(LogoBelgo32px, {}),
    _jsx(LogoA232px, {}),
    _jsx(LogoNatsumi32px, {}),
    _jsx(LogoInService32px, {}),
    _jsx(LogoGiba32px, {}),
    _jsx(LogoCopacabana32px, {}),
    _jsx(LogoResolv32px, {}),
];
const ClientsSection = () => {
    const isLargeScreen = useMediaQuery("(min-width:768px)");
    return (_jsx(BaseHomeSection, { id: "clientsSection", title: "Nossos clientes", children: _jsx("div", { className: styles.clientsLogos, children: isLargeScreen ? largeLogos : smallLogos }) }));
};
export default ClientsSection;
